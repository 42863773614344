
import Vue, { PropType } from 'vue'
import RPHButton from 'rph/atoms/RPHButton/index.vue'
import { IProfileAddress } from 'types/DTO/profile'
import LocationIcon from '~/assets/icons/address-location.svg?inline'
import { mapActions, mapMutations } from 'vuex'

export default Vue.extend({
  name: 'RPHHeaderLocationButton',
  components: {
    LocationIcon,
    RPHButton,
  },
  props: {
    city: {
      type: Object as PropType<any>,
    },
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    ...mapMutations({
      setCityModal: 'rph-contacts/setCityModal',
    }),
    selectAddress() {
      this.setCityModal(true)
    },
  },
})

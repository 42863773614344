
import { mapActions, mapGetters } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

/**
 * @vue-prop {String} phoneProp - phone for pre-fill data phone value
 * @vue-prop {Boolean} checkExist - enable check exist phone
 */
export default {
  name: 'SigninConfirmPhone',
  components: {
    ValidationObserver,
    ValidationProvider,
    Input: () => import('~/components/atoms/Input.vue'),
    Button: () => import('~/components/atoms/Button.vue'),
    ButtonTimeout: () => import('~/components/atoms/ButtonTimeout.vue'),
  },
  props: {
    phoneProp: {
      type: String,
      default: '',
    },
    checkExist: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    mask: '+7 ### ###-####',
    phone: '+7',
    code: '',
    phoneIsSended: false,
    codeIsCorrect: false,
    btnTimeoutActive: true,
    btnDisabled: false,
    captchaData: {
      active: false,
      token: null,
      error: '',
    },
  }),

  computed: {
    ...mapGetters({
      ModalType: 'signin/ModalType',
      SignInsCount: 'signin/SignInsCount'
    }),
  },

  watch: {
    async phoneIsSended(val) {
      setTimeout(() => {
        this.$refs.confirmPhoneForm.$el[2].focus()
      }, 0)
    },
    SignInsCount(val) {
      if (val > 3) {
        this.captchaData.active = true
      }
    },
    captchaData(val) {

    }
  },
  mounted() {
    if (this.phoneProp) {
      this.phone = this.phoneProp
    }
    if (this.SignInsCount >= 3) {
      this.captchaData.active = true
    }
  },
  methods: {
    ...mapActions({
      getCodeByPhone: 'signin/getAuthCodeByPhone',
      sendPhoneCode: 'signin/sendAuthPhoneCode',
    }),
    /**
     * Method send phone for get verificztion sms code
     */
    async checkPhone() {
      this.btnDisabled = true
      this.$store.commit('signin/SET_SIGNINS_COUNT', this.SignInsCount + 1)
      this.$refs.confirmPhoneForm.setErrors({ code: [] })
      this.$refs.confirmPhoneForm.errors.code = []
      this.$refs.confirmPhoneForm.reset()
      await this.$nextTick()
      const { phone, checkExist } = this
      try {
        const checkPhoneRes = await this.getCodeByPhone({
          phone,
          exist: checkExist ? 1 : 0,
        })

        if (checkPhoneRes.errors) {
          this.$refs.confirmPhoneForm.setErrors(checkPhoneRes.errors)
          this.phoneIsSended = false
          this.btnDisabled = false
        } else {
          this.phoneIsSended = true
          this.btnDisabled = false
        }

        return true
      } catch (err) {
        throw new Error(err)
      } finally {
        this.$nextTick(() => {
          this.$nuxt.$loading.finish()
        })
      }
    },
    /**
     * Method send verificztion sms code and return phone if it is correct
     */
    async sendCode(evt) {
      this.$nuxt.$loading.start()
      if (!this.code) this.$refs.confirmPhoneForm.setErrors({ code: ['Введите код'] })
      if (this.$refs.confirmPhoneForm.flags.valid) {
        const { phone, code } = this

        if (phone && code) {
          try {
            const codeRes = await this.sendPhoneCode({
              phone,
              code,
            })
            if (codeRes.errors) {
              this.$refs.confirmPhoneForm.setErrors({ code: ['Введён неверный код'] })
            } else {
              this.codeIsCorrect = true
              this.$emit('authorization', evt, codeRes)
            }
          } catch (err) {
            throw new Error(err)
          } finally {
            this.$nextTick(() => {
              this.$nuxt.$loading.finish()
            })
          }
        }
      }
    },
    captchaSuccess(token) {
      this.captchaData.token = token
      this.captchaData.error = ''
      this.btnDisabled = false
    },

    captchaError() {
      this.captchaData.token = null
      this.btnDisabled = true
    },

    captchaExpired() {
      this.captchaData.token = null
      this.btnDisabled = true
    }
  },
}

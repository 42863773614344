export default {
  ModalActive(state) {
    return state.modalActive
  },
  ModalType(state) {
    return state.signinType
  },
  SignInsCount(state) {
    return state.singnInsCount
  },
}

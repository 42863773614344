export default {
  SET_SIGNIN_ACTIVE(state, payload) {
    state.modalActive = payload
  },
  // auth | reg | restore
  SET_SIGNIN_TYPE(state, payload) {
    state.signinType = payload
  },
  SET_SIGNINS_COUNT(state, payload) {
    state.singnInsCount = payload
  },
}


import Vue from 'vue'

export default Vue.extend({
  name: 'RPHSmartBanner',

  props: {
    isHeaderShown: {
      type: Boolean,
      default: true,
    },
  },

  data: () => {
    return {
      isBannerOpen: false,
    }
  },

  computed: {
    isBannerAccess() {
      const isMobile = /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent)
      const isSafari =
        /Safari/i.test(navigator.userAgent) && !/Chrome|Chromium|CriOS/i.test(navigator.userAgent)
      return isMobile && !isSafari
    },
  },

  watch: {
    isHeaderShown(val) {
      if (this.isBannerAccess) {
        if (!val) {
          this.editBlockPadding(false)
        } else {
          this.editBlockPadding(true)
        }
      }
    },

    $route(val) {
      if (this.isBannerAccess) {
        setTimeout(() => {
          this.isBannerOpen = true
          this.editBlockPadding(true)
        }, 500)
      }
    },
  },

  mounted() {
    if (this.isBannerAccess) {
      this.isBannerOpen = true
      this.editBlockPadding(true)
    }
  },

  methods: {
    editBlockPadding(edit) {
      const mainPage = document.querySelector('.pages')
      const header = document.querySelector('.rph-header-mobile')
      const catalogPage = document.querySelector('.layout-page')
      const productPageTabs = document.querySelectorAll('.tabs-list')
      const breadcrumb = document.querySelector('.breadcrumb')
      if (mainPage) {
        mainPage.style.paddingTop = edit ? '118px' : '80px'
      }
      if (catalogPage) {
        catalogPage.style.paddingTop = edit ? '150px' : '130px'
      }

      if (productPageTabs[1]) {
        productPageTabs[1].style.paddingTop = edit ? '40px' : '0'
      }

      if (breadcrumb) {
        breadcrumb.style.paddingTop = edit ? '20px' : '0'
      }

      if (header) {
        header.style.paddingTop = edit ? '38px' : '0'
      }
    },

    openApp() {
      const appLink = `https://aurma.kz${this.$route.fullPath}`
      let pageHidden = false
      const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent)

      const fallbackLink = isIOS
        ? 'https://apps.apple.com/kz/app/aurma-kz-доставка-из-аптеки/id6702025702'
        : 'https://play.google.com/store/apps/details?id=aurma.android&hl=ru'
      document.addEventListener('visibilitychange', () => {
        if (document.hidden) {
          pageHidden = true
        }
      })

      window.location.href = appLink

      setTimeout(() => {
        if (!pageHidden) {
          window.location.href = fallbackLink
        }
      }, 1000)
    },
  },
})

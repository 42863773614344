import { render, staticRenderFns } from "./index.vue?vue&type=template&id=7d36e744&scoped=true"
import script from "./index.vue?vue&type=script&lang=ts"
export * from "./index.vue?vue&type=script&lang=ts"
import style0 from "./index.vue?vue&type=style&index=0&id=7d36e744&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d36e744",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RPHHeaderLocation: require('/usr/src/app/src/components/v2/organisms/RPHHeaderLocation/index.vue').default,RPHSearchBar: require('/usr/src/app/src/components/v2/organisms/RPHSearchBar/index.vue').default,RPHHeaderButtonGroup: require('/usr/src/app/src/components/v2/organisms/RPHHeaderButtonGroup/index.vue').default,RPHHeaderNav: require('/usr/src/app/src/components/v2/molecules/RPHHeaderNav/index.vue').default})

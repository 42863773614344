
import Vue from 'vue'
import RPHHeaderBasket from 'rph/molecules/RPHHeaderBasket/index.vue'
import RPHHeaderWhatsApp from 'rph/molecules/RPHHeaderWhatsApp/index.vue'
import RPHHeaderProfileControl from 'rph/molecules/RPHHeaderProfileControl/index.vue'
import RPHHeaderBonus from 'rph/organisms/RPHHeaderBonus/index.vue'
export default Vue.extend({
  name: 'RPHHeaderButtonGroup',
  components: {
    RPHHeaderBonus,
    RPHHeaderBasket,
    RPHHeaderWhatsApp,
    RPHHeaderProfileControl,
  },

  props: {
    collapse: Boolean,
  },
})


import Vue from 'vue'
import RPHHeaderLocationButton from 'rph/molecules/RPHHeaderLocationButton/index.vue'
import { IProfileAddress, IProfileAddressNew } from 'types/DTO/profile'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default Vue.extend({
  name: 'RPHHeaderLocation',
  components: {
    RPHHeaderLocationButton,
  },
  props: {},
  data() {
    return {}
  },

  fetchOnServer: false,

  mounted() {
    setTimeout(() => {
      if (!this.currentCity) {
        const currentCity =
          this.cities.find((city: any) => city.id === this.user.city_id) ||
          // @ts-ignore
          this.cities.find((city: any) => city.id === +localStorage.getItem('cityId')) ||
          this.cities.find((city: any) => city.is_default === 1)
        this.setCurrentCity(currentCity)
        localStorage.setItem('cityId', currentCity.id)
      }
    }, 1000)
  },
  computed: {
    ...mapGetters({
      cities: 'rph-contacts/cities',
      currentCity: 'rph-contacts/currentCity',
      user: 'rph-users/user',
    }),
    addresses(): IProfileAddress[] {
      return this.$store.getters['rph-profile/addresses'].map((address: IProfileAddressNew) => ({
        id: address?.id,
        title: `${address?.city.title}, ул.${address?.street}, д. ${address?.house} ${
          address.flat ? ', кв. ' + address.flat : ''
        }`,
        street: address.street,
        house: address.house,
        flat: address.flat,
        city_id: address.city.id,
        is_default: !!address.is_default,
      }))
    },
    defaultCity(): IProfileAddress | null {
      return this.$store.getters['rph-contacts/currentCity']
    },
  },
  methods: {
    ...mapMutations({
      setCurrentCity: 'rph-contacts/setCurrentCity',
    }),
  },
})

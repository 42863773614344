import { IHttp, IHttpParams, IHttpResponse } from 'services/common/Http'
import { CommonResponse } from 'types/DTO/common/response'
import { IUserOrder, ICities, IUserMindboxInfo } from 'types/DTO/profile'
import axios from 'axios'

type IDeleteResponse = {
  details: string
  status: number
}

export interface IProfile {
  FindAllAddresses(params: IHttpParams): Promise<IHttpResponse<CommonResponse<any>>>

  getOrders(params: IHttpParams): Promise<IHttpResponse<CommonResponse<IUserOrder>>>

  getOrdersByTitle(params: IHttpParams): Promise<IHttpResponse<CommonResponse<IUserOrder>>>

  deleteUserAddress(id: string, params: IHttpParams): Promise<CommonResponse<IDeleteResponse>>

  addUserAddress(data: any): Promise<any>

  editUserAddress(data: any): Promise<CommonResponse<any>>

  findAllCities(params: IHttpParams): Promise<IHttpResponse<CommonResponse<ICities[]>>>

  updateUserAddress(id: number, data: any): Promise<any>

  getAddressSuggest(params: IHttpParams): Promise<CommonResponse<any>>

  getUserMindboxInfo(id: number, params: IHttpParams): Promise<IHttpResponse<IUserMindboxInfo>>

  setUserMailingsAgreement(
    id: number,
    agreement: number,
    params: IHttpParams,
  ): Promise<CommonResponse<any>>
}

export default class Profile implements IProfile {
  constructor(private http: IHttp) {}

  async FindAllAddresses(params: IHttpParams): Promise<IHttpResponse<CommonResponse<any>>> {
    return await this.http.get('/api-v2/user-address', params || {})
  }

  async getOrders(params: IHttpParams): Promise<IHttpResponse<CommonResponse<IUserOrder>>> {
    return await this.http.get('/order-api', params || {})
  }

  async getOrdersByTitle(params: IHttpParams): Promise<IHttpResponse<CommonResponse<IUserOrder>>> {
    return await this.http.get('/order-api/search-by-title', params || {})
  }

  async deleteUserAddress(
    id: string,
    params: IHttpParams,
  ): Promise<CommonResponse<IDeleteResponse>> {
    return await this.http.delete(`/api-v2/user-address/${id}`, params || {})
  }

  async addUserAddress(data: any): Promise<any> {
    const formData = new FormData()
    for (const field in data) {
      formData.append(`${field}`, data[field])
    }

    return await this.http.post('/api-v2/user-address', formData)
  }

  async editUserAddress(data: any): Promise<CommonResponse<any>> {
    const formData = new FormData()
    for (const field in data) {
      formData.append(`UserAddress[${field}]`, data[field])
    }

    return await this.http.post(`/user-address-api/update/?id=${data.id}`, formData)
  }

  async updateUserAddress(id: number, data: any): Promise<any> {
    return await this.http.put(`/api-v2/user-address/${id}`, new URLSearchParams({ ...data }))
  }

  async findAllCities(params: IHttpParams): Promise<IHttpResponse<CommonResponse<ICities[]>>> {
    return await this.http.get('city-api/', params || {})
  }

  async getAddressSuggest(params: IHttpParams): Promise<CommonResponse<any>> {
    return await axios.get(
      `https://suggest-maps.yandex.ru/v1/suggest?apikey=${process.env.YANDEX_GEO_SUGGEST_KEY}&lang=ru&print_address=1&attrs=uri&types=house,biz`,
      { ...params },
    )
    // return await this.http.get(
    //   `https://suggest-maps.yandex.ru/v1/suggest?apikey=${process.env.YANDEX_GEO_SUGGEST_KEY}&lang=ru&print_address=1&bbox=43.273564,~76.914851`,
    //   params || {},
    // )
  }

  async getUserMindboxInfo(id: number, params?: IHttpParams): Promise<any> {
    return await this.http.get(`/api-v2/user/mind-box/${id}`, { ...(params || {}) })
  }

  async setUserMailingsAgreement(
    id: number,
    agreement: number,
    params: IHttpParams,
  ): Promise<CommonResponse<any>> {
    const formData = new FormData()
    //@ts-ignore
    formData.append(`consent_to_mailings`, agreement)

    return await this.http.put(
      `/api-v2/user/mind-box/${id}`,
      //@ts-ignore
      new URLSearchParams(formData),
      params,
    )
  }
}

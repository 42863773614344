
import { mapMutations, mapGetters } from 'vuex'
import RPHButton from 'rph/atoms/RPHButton/index.vue'
import { mutations } from '~/store/rph-contacts'
import { IProfileAddress } from 'types/DTO/profile'

export default {
  name: 'RPHCityModal',
  components: { RPHButton },

  props: {
    isDelivery: {
      type: Boolean,
      default: true,
    },
    basketProducts: {
      type: Array,
      default: [],
    },
  },

  data: () => {
    return {
      cityId: null,
      isUserSelect: false,
    }
  },

  computed: {
    ...mapGetters({
      cities: 'rph-contacts/cities',
      currentCity: 'rph-contacts/currentCity',
    }),

    selectPending() {
      return localStorage.getItem('cityId') || this.isUserSelect
    },

    defaultCity() {
      return this.currentCity?.id ?? this.cities.find((city) => city.is_default === 1).id
    },
  },

  methods: {
    ...mapMutations({
      setCityModal: 'rph-contacts/setCityModal',
      setCurrentCity: 'rph-contacts/setCurrentCity',
    }),

    closeModal() {
      this.setCityModal(false)
    },

    selectCity(id) {
      localStorage.setItem('cityId', id)
      document.cookie = `user.cityId=${id}; path=/;domain=aurma.kz; max-age=604800; SameSite=None; Secure`
      this.$store.commit(
        'rph-contacts/setCurrentCity',
        this.cities.find((city) => city.id === id),
      )
      this.$store.dispatch('rph-contacts/changeCity', {
        city_id: id,
      })
      setTimeout(() => {
        this.$store.dispatch('rph-users/getUser', {
          id: this.$store.state.auth.user.id,
        })
      }, 500)

      this.closeModal()
    },
  },
}
